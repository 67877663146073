import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '@components/layout/Layout'
import { resourceItems } from '@components/pages/resources/resource-items'
import ResourceKitItemComponent from '@components/pages/resources/resource-kit-item.component'
import SiteLink from '@components/SiteLink'
import imgAppScreen from '@assets/images/narcolepsy-screening-tools-app.png'

import './resource-center.scss'

class ResourceCenter extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div className="resource-order-form">
          <div className="primary-container page-header-section">
            <h1 className="main-page-header">NarcolepsyLink Resources</h1>
            <p className="page-comment">
              These NarcolepsyLink resources are provided to help you recognize manifestations of excessive daytime 
              sleepiness and other possible symptoms of narcolepsy in your patients. Download now.
            </p>
          </div>
          <div className="primary-container resource-kit-list">
            {resourceItems.map((item, index) => (
              <ResourceKitItemComponent
                key={index}
                resourceItemContent={item}
                pdf={item.pdf}
              />
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export default ResourceCenter
