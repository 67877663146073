import React from 'react'
import PropTypes from 'prop-types'
import { trackGa } from '@components/GoogleTracking'

import './resource-kit-item.component.scss'

const ResourceKitItemComponent = props => {
  let resourceKitItemClass = props.resourceItemContent.imgBig ? 'img-content big' : 'img-content'
  let content = (
    <div className="resource-kit-item" id={`resource-kit-item${props.resourceItemContent.title}`}>
      <div className={resourceKitItemClass}>
        <img src={props.resourceItemContent.image} alt={props.resourceItemContent.alt} />
      </div>
      <div className="resource-kit">
        <h2 className="resource-kit-title">{props.resourceItemContent.title}</h2>
        <p className="resource-kit-comment">{props.resourceItemContent.description}</p>
        <div className="button-group">
          <a href={props.pdf} target="_blank">
            <button
              className="btn-standard view-btn"
              onClick={() => {
                trackGa(props.resourceItemContent.viewTracking)
              }}
            >
              View
            </button>
          </a>
          <a href={props.pdf} download>
            <button
              className="btn-standard download-print"
              onClick={() => {
                trackGa(props.resourceItemContent.tracking)
              }}
            >
              Download
            </button>
          </a>
        </div>
      </div>
    </div>
  )
  if (props.resourceItemContent.visible === false) content = null
  return content
}

ResourceKitItemComponent.propTypes = {
  resourceItemContent: PropTypes.object.isRequired,
  totalCartCount: PropTypes.number.isRequired,
  addToCart: PropTypes.func,
  goToPage: PropTypes.func,
  pdf: PropTypes.string
}

export default ResourceKitItemComponent
